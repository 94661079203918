import React, { Fragment } from 'react'
const images = [
    "../images/yoeya-traditional-home-1.jpg",
    "../images/yoeya-traditional-home-2.jpg",
    "../images/yoeya-traditional-home-3.jpg",
    "../images/yoeya-traditional-home-4.jpg",
    "../images/yoeya-traditional-home-5.jpg",
    "../images/yoeya-traditional-home-6.jpg",
    "../images/yoeya-traditional-home-7.jpg",
    "../images/yoeya-traditional-home-8.jpg",
    "../images/yoeya-traditional-home-9.jpg",
    "../images/yoeya-traditional-home-10.jpg",
    "../images/yoeya-traditional-home-11.jpg"
];

function Home() {
    const [imgValue, setImgValue] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setImgValue((v) => {
                let randomNumber = Math.floor(Math.random() * (images.length - 0) + 0);
                return randomNumber;
            });
        }, 7000);
        return () => clearInterval(interval);
    }, []);
    return (
        <Fragment>
            <div className="container">
                <div className='box'>
                    <div className="flex-container">
                        <div className="flex-child ">
                            <img src={images[imgValue]} alt="" />
                        </div>
                        <div className="flex-child ">
                            <p><span className='yoeya-l-text'>Yoeya</span> means tradition.</p>
                            <p>Our traditions are ingrained in how we weave our fabric and dye it with Magwe's flowers and fruits.</p>
                            <p>They're in our techniques of hand-crafting lacquerware and jewellery with our sisters.</p>
                            <p>They're in our values of community leadership, justice and equity; in ensuring our profits are all reinvested into improving the lives of the people and families around us.</p>
                            <p>They're in our principles of sustainability and ethically sourcing all raw-materials, and in ensuring that all our products are high quality, as well as cruelty and child-labour free.</p>
                            <p>Our traditions can be revived and passed on to others. Former teachers and university students, dismissed after resisting the military coup, have now taken on the age-old local weaving and sewing techniques. These are the women of Yoeya.</p>
                            <p>Buying from <span className="yoeya-s-text">Yoeya</span> is supporting self-reliance and resilience in the face of corporate and state-inflicted injustice that surrounds us in Myanmar. It means helping to create an alternative future for women who may otherwise become disempowered domestic helpers and low-skilled factory workers in neighbouring countries.</p>
                            <p>Our products prove that you can have quality and style without compromising on ethical values. We commission individuals and family-owned businesses in Myanmar and always pay their asking price. There are no middle agents involved in the lifecycle of our products.</p>
                            <p><span className="yoeya-s-text">Yoeya</span> means tradition, but it means a lot more than that to the people involved.</p>
                            <br /><br />
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>

    );
}

export default Home;