import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from 'pages/home/Home';
import AboutUs from 'pages/aboutUs/AboutUs';
import './styles/App.css';

function App() {

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Navigate replace to="/home" />} />
                <Route path="home" element={<Home />} />
                <Route path="*" element={<Navigate replace to="/home" />} />
            </Routes>
        </div>
    );
}

export default App;
